import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Routes as AntRoutes, Route } from 'react-router-dom';

import { selectAppSlice } from '../../app/slice';
import AddBrand from './AddBrand';
import AddCategory from './AddCategory';
import AddInfluencer from './AddInfluencer';
import Aira from './Aira';
import { Campaign } from './Campaign/';
import ComparativeTable from './ComparativeTable';
import ComparativeTablePDF from './ComparativeTablePDF';
import Home from './Home';
import InfluencerCard from './InfluencerCard';
import InfluencerCardPDF from './InfluencerCardPDF';
import InfluencerList from './InfluencerList';
import Notifications from './Notifications';
import PlannerList from './PlannerList';
import PlannerListCard from './PlannerListCard';
import PlannerListCardPDF from './PlannerListCardPDF';
import Solicitation from './Solicitation';

import pageErrorImage from '../../common/assets/images/page-error.png';
import { Workspaces } from './Workspaces';

const Routes = () => {
  const { t } = useTranslation();
  const slice = useSelector(selectAppSlice);
  const user = slice.user;

  return (
    <AntRoutes>
      <Route exact path="/" element={<Home />} />
      <Route path="/card/:id" element={<InfluencerCard />} />
      <Route path="/card/:id/pdf" element={<InfluencerCardPDF />} />
      <Route path="/card/:id/:notificationId" element={<InfluencerCard />} />
      <Route path="/planner-lists" element={<PlannerList />} />
      <Route path="/planner-lists/:id/card" element={<PlannerListCard />} />
      <Route path="/planner-lists/:id/card/pdf" element={<PlannerListCardPDF />} />
      <Route path="/list/:id/:name" element={<InfluencerList />} />
      <Route path={'/planner-lists/:id/comparative-table/:nw'} element={<ComparativeTable />} />
      <Route path={'/planner-lists/:id/comparative-table'} element={<ComparativeTable />} />
      <Route
        path="/planner-lists/:id/comparative-table/:nw/pdf"
        element={<ComparativeTablePDF />}
      />
      <Route path="/dataset/brand" element={<AddBrand />} />
      <Route path="/dataset/theme" element={<AddCategory />} />
      <Route path="/add-influencer" element={<AddInfluencer />} />
      {user.features.includes('aira') && <Route path="/aira" element={<Aira />} />}
      <Route path="/notification/:id" element={<Solicitation />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/workspaces/" element={<Workspaces />} />
      <Route path="/workspaces/:campaignId" element={<Campaign />} />
      <Route
        path="*"
        element={
          <div className="page-not-found">
            <div className="feedback-error">
              <img
                src={pageErrorImage}
                alt={t(
                  'um homem e duas mulheres trabalhando com seus notebooks sentados em um notebook gigante',
                )}
              />

              <span>
                <b>{t('Esta página não existe')}.</b>
              </span>
            </div>
          </div>
        }
      />
    </AntRoutes>
  );
};

export default Routes;
