import moment from 'moment';
import { z } from 'zod';

const DATE_FORMAT = 'DD/MM/YYYY';

const dateSchema = z
  .string()
  .min(1, { message: 'Data é obrigatória.' })
  .refine((value) => moment(value, DATE_FORMAT, true).isValid(), {
    message: `Formato de data inválido.`,
  });

const initialDateSchema = dateSchema
  .transform((value) => moment(value, DATE_FORMAT).toISOString())
  .refine(
    (value) => {
      const today = moment().startOf('day');
      const inputDate = moment(value).startOf('day');
      return inputDate.isSameOrAfter(today);
    },
    { message: 'Data de início deve ser posterior ou igual à data atual.' },
  )
  .default('');

const finalDateSchema = dateSchema
  .transform((value) => moment(value, DATE_FORMAT).toISOString())
  .refine(
    (value) => {
      const today = moment().startOf('day');
      const inputDate = moment(value).startOf('day');
      return inputDate.isSameOrAfter(today);
    },
    { message: 'Data final deve ser posterior ou igual à data atual.' },
  )
  .default('');

export const schema = z
  .object({
    title: z
      .string()
      .min(1, 'Título é obrigatório.')
      .min(3, 'Título deve ter no mínimo 3 caracteres.')
      .default(''),
    mentions: z.string().nullable(),
    start_date: initialDateSchema,
    end_date: finalDateSchema,
  })
  .refine(
    (data) => {
      console.log(data.start_date);
      console.log(data.end_date);

      return moment(data.end_date).isSameOrAfter(moment(data.start_date));
    },
    {
      message: 'Data final deve ser posterior ou igual à data de início.',
      path: ['end_date'],
    },
  );
