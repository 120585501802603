import PropTypes from 'prop-types';
import { useState } from 'react';

import Button from '../../../../../common/components/Button';
import Input from '../../../../../common/components/Input';
import Modal from '../../../../../common/components/Modal';
import { useModal } from '../../contexts/ModalProvider';

import './styles.scss';

export const RenameColumnModal = ({ onSave, data }) => {
  const [value, setValue] = useState(data.title);
  const { isOpen, closeModal } = useModal();

  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleSave = async () => {
    await onSave(value);
  };

  return (
    <Modal
      title="Renomear lista"
      subtitle="Insira um novo nome para a lista"
      isVisible={isOpen}
      onClose={closeModal}
      className="add_influencer_modal__rm"
    >
      <Input
        placeholder="Nome da lista"
        value={value}
        onChange={handleChangeValue}
        hasFocus={true}
      />

      <div className="buttons__add_influencer_modal__rm">
        <Button type="button" variant="outline" onClick={closeModal}>
          Cancelar
        </Button>
        <Button type="button" onClick={handleSave}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
};

RenameColumnModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};
