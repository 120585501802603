import { Copy, Pencil, Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';

import { useModal } from '../contexts/ModalProvider';
import { DeleteCampaignModal } from './DeleteCampaignModal';
import { DuplicateCampaignModal } from './DuplicateCampaignModal';
import { UpdateCampaignModal } from './UpdateCampaignModal';

export const TableActions = ({ campaign }) => {
  const { openModal } = useModal();

  const handleDuplicateCampaign = () => {
    openModal(<DuplicateCampaignModal row={campaign} />);
  };

  const handleEditCampaign = () => {
    openModal(<UpdateCampaignModal row={campaign} />);
  };

  const handleDeleteCampaign = () => {
    openModal(<DeleteCampaignModal campaignId={campaign.uid} />);
  };

  return (
    <div>
      <button type="button" onClick={handleDuplicateCampaign}>
        <Copy size={20} />
      </button>
      <button type="button" onClick={handleEditCampaign}>
        <Pencil size={20} />
      </button>
      <button type="button" onClick={handleDeleteCampaign}>
        <Trash2 size={20} />
      </button>
    </div>
  );
};

TableActions.propTypes = {
  campaign: PropTypes.object,
};
