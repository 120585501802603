import PropTypes from 'prop-types';

import Button from '../../../../../common/components/Button';
import Modal from '../../../../../common/components/Modal';
import { useModal } from '../../contexts/ModalProvider';

import './styles.scss';

export const SuccessfullyDuplicatedCampaignModal = ({ campaignId }) => {
  const { isOpen, closeModal } = useModal();

  const handleFollowToNewCampaign = () => {
    closeModal();
    window.open(`/#/workspaces/${campaignId}`, '_self');
  };

  return (
    <Modal
      isVisible={isOpen}
      onClose={closeModal}
      className="successfully_duplicated_campaign_modal"
    >
      <div className="content__successfully_duplicated_campaign_modal">
        <span className="icon">
          <svg
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 8.99984L9.33333 16.3332L24 1.6665"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>

        <strong>Sua Área de Trabalho foi duplicada</strong>
        <p>
          Você pode <b>Seguir</b> para a Copia da Área de trabalho ou <b>Cancelar</b> a ação. A Área
          de trabalho duplicada pode ser localizada na tela inicial como “(Copy)” depois do nome.
        </p>

        <div>
          <Button onClick={closeModal}>Cancelar</Button>
          <Button onClick={handleFollowToNewCampaign}>Seguir</Button>
        </div>
      </div>
    </Modal>
  );
};

SuccessfullyDuplicatedCampaignModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
