import Button from '../../../common/components/Button';
import Header from '../../../common/components/Header';
import Input from '../../../common/components/Input';
import { CreateCampaignModal } from './components/CreateCampaignModal';
import { Modal } from './components/Modal';
import { Table } from './components/Table';
import { ModalContext, ModalProvider } from './contexts/ModalProvider';
import { WorkspacesProvider } from './contexts/WorkspacesProvider';

import './styles.scss';

export const Workspaces = () => {
  return (
    <WorkspacesProvider>
      <ModalProvider>
        <ModalContext.Consumer>
          {({ openModal }) => (
            <>
              <div className="background-page workspaces">
                <div className="container__workspaces">
                  <Header title="Áreas de trabalho" />

                  <div>
                    <Input
                      className="input-name"
                      prefix={<i className="icon icon-search" />}
                      placeholder="Pesquise por palavras-chave ou nomes das áreas de trabalho"
                    />
                    <Button
                      icon="plus"
                      className="pattern-dark"
                      onClick={() => openModal(<CreateCampaignModal />)}
                    >
                      Área de trabalho
                    </Button>
                    <Button> Pesquisar</Button>
                  </div>

                  <main>
                    <Table />
                  </main>
                </div>
              </div>

              <Modal />
            </>
          )}
        </ModalContext.Consumer>
      </ModalProvider>
    </WorkspacesProvider>
  );
};
