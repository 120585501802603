import { useCallback, useEffect, useState } from 'react';

export const useRequest = (requestFn, { initialData = null, deps = [], enabled = true }) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(!enabled);

  const handleFetch = useCallback(async () => {
    if (!enabled) return;

    setIsLoading(true);
    setError(null);

    try {
      const res = await requestFn();
      setData(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, deps);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return { data, error, isLoading, setData, refetch: handleFetch };
};
