import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Select as AntSelect } from 'antd';
import Loading from '../Loading';
//
import './index.scss';

const { Option } = AntSelect;

const SelectSearch = ({
  onChange,
  onSearch,
  options,
  isDisabled,
  placeholder,
  defaultValue,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <AntSelect
      showSearch
      labelInValue
      onSearch={onSearch}
      className="search-select"
      disabled={isDisabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      showArrow={true}
      optionFilterProp="children"
      onChange={(value) => onChange(value)}
      suffixIcon={<i className="icon icon-search" />}
      notFoundContent={isLoading ? <Loading /> : <p className="text-empty">{t('Sem resultado')}</p>}
      {...props}
    >
      {options &&
        options.map((option, key) => {
          return (
            <Option key={key} className={option['key']} value={option['value']}>
              {option['key']}
            </Option>
          );
        })}
    </AntSelect>
  );
};

SelectSearch.defaultValue = {
  isDisabled: false,
  isLoading: false,
  onChange: () => {},
  onSearch: () => {},
  options: PropTypes.arrayOf(PropTypes.object),
};

SelectSearch.propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default SelectSearch;
