import { EllipsisVertical, Lock, Pencil, Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import Button from '../../../../common/components/Button';
import Dropdown from '../../../../common/components/DropDown';
import feedback from '../../../../common/components/Feedback';
import { useMutation } from '../../../../hooks/useMutation';
import { useCampaign } from '../contexts/CampaignProvider';
import { useModal } from '../contexts/ModalProvider';
import { WorkspaceService } from '../service';
import { AddInfluencerModal } from './AddInfluencerModal';
import { CardList } from './CardList';
import { DeleteColumnModal } from './DeleteColumnModal';
import { RenameColumnModal } from './RenameColumnModal';

export const Column = ({ column, index }) => {
  const { setCampaign, campaignId } = useCampaign();
  const { openModal, closeModal } = useModal();

  const renameColumnMutation = useMutation(WorkspaceService.columns.update);
  const deleteColumnMutation = useMutation(WorkspaceService.columns.delete);

  const handleRenameColumn = async (title) => {
    try {
      await renameColumnMutation.mutateAsync(campaignId, column.uid, { title });

      setCampaign((prev) => ({
        ...prev,
        columns: prev.columns.map((item) => {
          if (item.uid === column.uid) {
            return {
              ...item,
              title,
            };
          }

          return item;
        }),
      }));

      closeModal();
      feedback.success('Coluna renomeada com sucesso.');
    } catch {
      feedback.error('Erro ao renomear coluna.');
    }
  };

  const handleDeleteColumn = async () => {
    try {
      await deleteColumnMutation.mutateAsync(campaignId, column.uid);

      setCampaign((prev) => ({
        ...prev,
        columns: prev.columns.filter((item) => item.uid !== column.uid),
      }));

      closeModal();
      feedback.success('Coluna excluída com sucesso.');
    } catch {
      feedback.error('Erro ao excluir coluna.');
    }
  };

  const handleColumnAction = async (key) => {
    if (key === 'rename') {
      openModal(<RenameColumnModal onSave={handleRenameColumn} data={column} />);
    }

    if (key === 'delete') {
      openModal(<DeleteColumnModal onSave={handleDeleteColumn} data={column} />);
    }
  };

  const handleOpenAddInfluencerModal = (columnId) => {
    openModal(<AddInfluencerModal columnId={columnId} />);
  };

  return (
    <Draggable
      key={column.uid}
      draggableId={column.uid}
      index={index}
      isDragDisabled={column.locked}
    >
      {(provided) => (
        <div
          className="column_rm"
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{ ...provided.draggableProps.style, '--header-color': column.color }}
        >
          <header {...provided.dragHandleProps}>
            <b>{column.title}</b>
            {column.locked && <Lock />}
            {!column.locked && (
              <Dropdown
                options={[
                  {
                    icon: <Pencil size={20} />,
                    key: 'rename',
                    label: 'Renomear lista',
                  },
                  {
                    icon: <Trash2 size={20} />,
                    key: 'delete',
                    label: 'Excluir lista',
                  },
                ]}
                onSelect={(key) => handleColumnAction(key)}
              >
                <button type="button">
                  <EllipsisVertical />
                </button>
              </Dropdown>
            )}
          </header>
          <Droppable droppableId={column.uid} type="QUOTE">
            {(provided, snapshot) => (
              <div
                className="cards_rm"
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ backgroundColor: snapshot.isDraggingOver ? '#ededed' : '' }}
              >
                <CardList cards={column.cards} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <footer>
            <Button type="button" onClick={() => handleOpenAddInfluencerModal(column.uid)}>
              Adicionar influenciador
            </Button>
          </footer>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};
Column.propTypes = {
  column: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['system', 'custom']).isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    locked: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    implicit_status: PropTypes.oneOfType([PropTypes.string, PropTypes.null]).isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        order: PropTypes.number.isRequired,
        air_id: PropTypes.string.isRequired,
        posts_count: PropTypes.number.isRequired,
        videos_count: PropTypes.number.isRequired,
        network: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        profile_id: PropTypes.string.isRequired,
        stories_count: PropTypes.number.isRequired,
        status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
