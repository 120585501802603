import { useCallback, useState } from 'react';

export const useMutation = (service) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [context, setContext] = useState(null);

  const mutateAsync = useCallback(
    async (...requestData) => {
      setIsLoading(true);
      setError(null);
      setIsSuccess(false);
      setContext(...requestData);

      try {
        const response = service(...requestData);

        setData(response);
        setIsSuccess(true);
        return response;
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [service],
  );

  return { mutateAsync, data, error, isLoading, isSuccess, context };
};
