import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import { useRequest } from '../../../../hooks/useRequest';
import { WorkspaceService } from '../../Campaign/service';

export const WorkspacesContext = createContext({});

export const WorkspacesProvider = ({ children }) => {
  const { data, setData } = useRequest(() => WorkspaceService.campaigns.getAll(0), {
    deps: [],
  });

  return (
    <WorkspacesContext.Provider
      value={{
        workspaces: data,
        setWorkspaces: setData,
      }}
    >
      {children}
    </WorkspacesContext.Provider>
  );
};

export const useWorkspaces = () => {
  const context = useContext(WorkspacesContext);

  if (!context) {
    throw new Error('useWorkspaces must be used within a WorkspacesContext');
  }

  return context;
};

WorkspacesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
