import { Board } from './components/Board';
import { Header } from './components/Header';
import { Modal } from './components/Modal';
import { CampaignProvider } from './contexts/CampaignProvider';
import { ModalProvider } from './contexts/ModalProvider';

import './styles.scss';

export const Campaign = () => {
  return (
    <CampaignProvider>
      <ModalProvider>
        <div className="background-page rm">
          <div className="container_rm">
            <Header />

            <Board />
          </div>
        </div>

        <Modal />
      </ModalProvider>
    </CampaignProvider>
  );
};
