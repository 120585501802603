import PropTypes from 'prop-types';

import Button from '../../../../../common/components/Button';
import feedback from '../../../../../common/components/Feedback';
import Modal from '../../../../../common/components/Modal';
import { useMutation } from '../../../../../hooks/useMutation';
import { WorkspaceService } from '../../../Campaign/service';
import { useModal } from '../../contexts/ModalProvider';
import { useWorkspaces } from '../../contexts/WorkspacesProvider';

import './styles.scss';

export const DeleteCampaignModal = ({ campaignId }) => {
  const { isOpen, closeModal } = useModal();
  const { setWorkspaces } = useWorkspaces();
  const deleteCampaignMutation = useMutation(WorkspaceService.campaigns.delete);

  const handleDeleteCampaign = async () => {
    try {
      await deleteCampaignMutation.mutateAsync(campaignId);

      setWorkspaces((prev) => ({
        ...prev,
        items: prev.items.filter((workspace) => workspace.uid !== campaignId),
      }));

      closeModal();
      feedback.success('Área de trabalho deletada com sucesso.');
    } catch {
      feedback.error('Falha ao deletar área de trabalho.');
    }
  };

  return (
    <Modal isVisible={isOpen} onClose={closeModal} className="delete_campaign_modal">
      <div className="content__delete_campaign_modal">
        <span className="icon">
          <svg
            width="6"
            height="35"
            viewBox="0 0 6 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21V3C6 1.34315 4.65685 0 3 0ZM3 35C4.65685 35 6 33.6569 6 32C6 30.3431 4.65685 29 3 29C1.34315 29 0 30.3431 0 32C0 33.6569 1.34315 35 3 35Z"
              fill="white"
            />
          </svg>
        </span>

        <b>Sua Área de Trabalho será excluída</b>
        <p>
          Você pode cofirmar, se deseja fazer isso ou então cancelar. Ao optar por excluír, você
          retornará para a página inicial.
        </p>

        <div>
          <Button onClick={closeModal}>Cancelar</Button>
          <Button onClick={handleDeleteCampaign}>Excluir</Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteCampaignModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
