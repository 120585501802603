import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../../../../common/components/Button';
import feedback from '../../../../../common/components/Feedback';
import Input from '../../../../../common/components/Input';
import Modal from '../../../../../common/components/Modal';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { useCampaign } from '../../contexts/CampaignProvider';
import { useModal } from '../../contexts/ModalProvider';
import { WorkspaceService as Service } from '../../service';
import { ProfileList } from './ProfileList';
import { SelectedProfile } from './SelectedProfile';

import './styles.scss';

export const AddInfluencerModal = ({ columnId }) => {
  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [influencer, setInfluencer] = useState(null);
  const { setCampaign, campaignId } = useCampaign();

  const searchDebounced = useDebounce(search);

  const enabled = searchDebounced.length >= 3;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(!enabled);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { isOpen, closeModal } = useModal();

  const observer = useRef();

  const handleFetch = useCallback(async () => {
    if (!enabled || (data?.items.length === totalCount && totalCount > 0)) return;

    setIsLoading(true);

    try {
      const res = await Service.profiles.search({ query: searchDebounced, page: currentPage });
      setData((prev) => {
        const newItems = res.data.items || [];
        return prev ? { ...prev, items: [...prev.items, ...newItems] } : res.data;
      });
      setTotalCount(res.data.count);
    } catch {
      feedback.error('Falha ao buscar perfis.');
    } finally {
      setIsLoading(false);
    }
  }, [searchDebounced, currentPage]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    if (!searchDebounced.length) {
      handleClearData();
    }
  }, [searchDebounced]);

  const handleClearData = () => {
    setData(null);
    setCurrentPage(0);
    setTotalCount(0);
  };

  useEffect(() => {
    if (searchDebounced !== previousSearch) {
      handleClearData();
      setPreviousSearch(searchDebounced);
    }
  }, [searchDebounced, previousSearch]);

  const handleSelectProfile = (profile) => {
    setInfluencer(profile);
    setSearch('');
    handleClearData();
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleRemoveInfluencer = () => {
    setInfluencer(null);
  };

  const handleAddProfile = async () => {
    try {
      const { data } = await Service.profiles.add(campaignId, {
        column_uid: columnId,
        profile_id: influencer.profile_id,
      });

      setCampaign((prev) => ({
        ...prev,
        columns: prev.columns.map((column) => {
          if (column.uid === columnId) {
            return {
              ...column,
              cards: [...column.cards, data.profile],
            };
          }

          return column;
        }),
      }));
      feedback.success('Perfil adicionado com sucesso.');
      closeModal();
    } catch {
      feedback.error('Falha ao adicionar perfil.');
    }
  };

  const lastProfileElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading],
  );

  return (
    <Modal
      title="Adicionar influenciador"
      subtitle="Coloque o nome do influencer ou o seu arroba"
      isVisible={isOpen}
      onClose={closeModal}
      className="add_influencer_modal__rm"
    >
      {!influencer && (
        <Input
          placeholder="@influenciador ou nome do influenciador"
          value={search}
          onChange={handleSearch}
        />
      )}

      <SelectedProfile profile={influencer} onRemove={handleRemoveInfluencer} />

      {!influencer && data && totalCount > 0 && (
        <ProfileList
          profiles={data.items}
          onSelectProfile={handleSelectProfile}
          ref={lastProfileElementRef}
          isLoading={isLoading}
        />
      )}

      {enabled && totalCount === 0 && (
        <div className="no-results__add_influencer_modal__rm">
          <p>Nenhum resultado encontrado</p>
        </div>
      )}

      {influencer && (
        <div className="buttons__add_influencer_modal__rm">
          <Button variant="outline" type="button" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="button" onClick={handleAddProfile}>
            Adicionar
          </Button>
        </div>
      )}
    </Modal>
  );
};

AddInfluencerModal.propTypes = {
  columnId: PropTypes.string.isRequired,
};
