import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';

import Button from '../../../../../common/components/Button';
import Input from '../../../../../common/components/Input';
import Modal from '../../../../../common/components/Modal';
import { maskDate } from '../../../../../utils/masks';
import { useModal } from '../../contexts/ModalProvider';
import { schema } from './schema';

import './styles.scss';

export const CreateCampaignModal = () => {
  const { isOpen, closeModal } = useModal();
  const {
    formState: { errors },
    ...form
  } = useForm({
    resolver: zodResolver(schema),
  });

  const handleSubmit = form.handleSubmit((data) => {
    console.log(data);
  }, console.log);

  return (
    <Modal
      title="Criação de Área de Trabalho"
      subtitle="Adicione sua campanha aqui e conecte-se com influenciadores de forma prática dispensando planilhas,
tornando o processo mais produtivo!"
      isVisible={isOpen}
      onClose={closeModal}
      className="create_campaign_modal"
    >
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Nome da Área de Trabalho</label>
          <Controller
            name="title"
            control={form.control}
            render={({ field }) => (
              <Input
                placeholder="Novos Influenciadores de culinária"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.title && <span>{errors.title.message}</span>}
        </div>
        <div>
          <label htmlFor="mentions">Hashtags e menções</label>
          <p>Insira abaixo as #hashtags que deseja monitorar no período da campanha.</p>
          <Controller
            name="mentions"
            control={form.control}
            render={({ field }) => (
              <Input
                placeholder="#airfluencers @airfluencers"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.mentions && <span>{errors.mentions.message}</span>}
        </div>
        <div>
          <b>Período</b>
          <div>
            <div>
              <label htmlFor="start_date">Início</label>
              <Controller
                name="start_date"
                control={form.control}
                render={({ field }) => (
                  <Input
                    placeholder="DD/MM/AAAA"
                    value={field.value}
                    onChange={(event) => {
                      const maskedValue = maskDate(event.target.value);
                      field.onChange(maskedValue);
                    }}
                  />
                )}
              />
              {errors.start_date && <span>{errors.start_date.message}</span>}
            </div>
            <div>
              <label htmlFor="end_date">Final</label>
              <Controller
                name="end_date"
                control={form.control}
                render={({ field }) => (
                  <Input
                    placeholder="DD/MM/AAAA"
                    value={field.value}
                    onChange={(event) => {
                      const maskedValue = maskDate(event.target.value);
                      field.onChange(maskedValue);
                    }}
                  />
                )}
              />
              {errors.end_date && <span>{errors.end_date.message}</span>}
            </div>
          </div>
        </div>

        <footer>
          <Button type="button" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="submit" onClick={handleSubmit}>
            Criar
          </Button>
        </footer>
      </form>
    </Modal>
  );
};
