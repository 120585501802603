import PropTypes from 'prop-types';

import Button from '../../../../../common/components/Button';
import Modal from '../../../../../common/components/Modal';
import { useModal } from '../../contexts/ModalProvider';

import './styles.scss';

export const DeleteColumnModal = ({ data, onSave }) => {
  const { isOpen, closeModal } = useModal();

  const canDelete = !data.cards.locked && data.cards.length === 0;

  return (
    <Modal isVisible={isOpen} onClose={closeModal} className="delete_column_modal">
      <div className="content__delete_column_modal">
        <span className="icon">
          <svg
            width="6"
            height="35"
            viewBox="0 0 6 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24C4.65685 24 6 22.6569 6 21V3C6 1.34315 4.65685 0 3 0ZM3 35C4.65685 35 6 33.6569 6 32C6 30.3431 4.65685 29 3 29C1.34315 29 0 30.3431 0 32C0 33.6569 1.34315 35 3 35Z"
              fill="white"
            />
          </svg>
        </span>

        {canDelete && (
          <>
            <b>Sua lista será excluída</b>
            <p>Você pode cofirmar, se deseja fazer isso ou então cancelar.</p>
          </>
        )}

        {!canDelete && (
          <>
            <b>Você não pode excluir esta lista</b>
            <p>
              A lista que você deseja excluir possuir cards com influenciadores. Para excluir essa
              lista é necessário remover ou mover todos os cards.
            </p>
          </>
        )}

        {canDelete && (
          <div>
            <Button onClick={closeModal}>Cancelar</Button>
            <Button onClick={onSave}>Excluir</Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

DeleteColumnModal.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['system', 'custom']).isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    locked: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    implicit_status: PropTypes.oneOfType([PropTypes.string, PropTypes.null]).isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        order: PropTypes.number.isRequired,
        air_id: PropTypes.string.isRequired,
        posts_count: PropTypes.number.isRequired,
        videos_count: PropTypes.number.isRequired,
        network: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
        profile_id: PropTypes.string.isRequired,
        stories_count: PropTypes.number.isRequired,
        status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};
