import { useState } from 'react';

import { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(null);

  const openModal = (children) => {
    setModal(children);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModal(null);
  };

  return (
    <ModalContext.Provider value={{ isOpen, modal, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
