import { useState } from 'react';

import Button from '../../../../../common/components/Button';
import Input from '../../../../../common/components/Input';
import Modal from '../../../../../common/components/Modal';
import { useModal } from '../../contexts/ModalProvider';
import { WorkspaceService } from '../../service';

import feedback from '../../../../../common/components/Feedback';
import { useMutation } from '../../../../../hooks/useMutation';
import { useCampaign } from '../../contexts/CampaignProvider';
import './styles.scss';

export const AddColumnModal = () => {
  const [value, setValue] = useState('');

  const { setCampaign, campaignId } = useCampaign();
  const { isOpen, closeModal } = useModal();

  const mutation = useMutation(WorkspaceService.columns.create);

  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleAddColumn = async () => {
    try {
      const { data } = await mutation.mutateAsync(campaignId, { title: value });

      setCampaign((prev) => ({
        ...prev,
        columns: [data.column, ...prev.columns],
      }));
      closeModal();
      feedback.success('Coluna adicionada com sucesso.');
    } catch {
      feedback.error('Erro ao adicionar coluna.');
    }
  };

  return (
    <Modal
      title="Adicionar lista"
      subtitle="Insira um nome para a lista"
      isVisible={isOpen}
      onClose={closeModal}
      className="add_influencer_modal__rm"
    >
      <Input
        placeholder="Nome da lista"
        value={value}
        onChange={handleChangeValue}
        hasFocus={true}
      />

      <div className="buttons__add_influencer_modal__rm">
        <Button type="button" variant="outline" onClick={closeModal}>
          Cancelar
        </Button>
        <Button type="button" onClick={handleAddColumn}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
};
